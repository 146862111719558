import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay,  Navigation, Pagination } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';

  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";
  import"../../style/home/portada/portada.css"

  /* imagenes */
 
  import portada from "../../img/home/portada/1.jpg";
  import responsiva from "../../img/home/portada/1responsivo.jpg";
  import portada1 from "../../img/home/portada/2.jpg";
  import responsiva1 from "../../img/home/portada/2responsivo.jpg";
  import portada2 from "../../img/home/portada/3.jpg";
  import responsiva2 from "../../img/home/portada/3responsivo.jpg";
  import portada3 from "../../img/home/portada/4.jpg";
  import responsivo3 from "../../img/home/portada/4responsivo.jpg";
  import portada4 from "../../img/home/portada/5.jpg";
  import responsivo4 from "../../img/home/portada/5responsivo.jpg";
  import portada5 from "../../img/home/portada/6.jpg";
  import responsivo5 from "../../img/home/portada/6responsivo.jpg";
  import portada6 from "../../img/home/portada/7.jpg";
  import responsivo6 from "../../img/home/portada/7responsivo.jpg";


  const data = [
    {
      originalImage: portada,
      responsiveImage: responsiva,
      subtitle: "Las personas están en el centro",
      title: "de todo lo que hacemos",
      seo:" equipos personas axinntus"
    },
    {
      originalImage: portada1,
      responsiveImage: responsiva1,
      subtitle: "Desarrollamos soluciones industriales",
      title: "seguras e innovadoras para nuestros clientes",
      seo:"  mantenimiento operación industriales"
    },
    {
      originalImage: portada2,
      responsiveImage: responsiva2,
      subtitle: "Presencia nacional con contratos a largo plazo",
      title: "para las industrias de minería, energía y química",
      seo:"multiculturalidad axinntus"
    },
    {
      originalImage: portada3,
      responsiveImage: responsivo3,
      subtitle: "Trabajamos con los más altos estándares de",
      title: "seguridad en nuestras operaciones",
      seo:"seguridad axinntus"
    },
    {
      originalImage: portada4,
      responsiveImage: responsivo4,
      subtitle: "Líderes en servicios industriales de",
      title: "excelencia para la minería",
      seo:"servicios industriales"

    },
    {
      originalImage: portada5,
      responsiveImage: responsivo5,
      subtitle: "Comprometidos con el desarrollo de nuestros",
      title: "colaboradores, clientes y comunidades",
      seo:"activos de nuestros equipos"
    },
    {
      originalImage: portada6,
      responsiveImage: responsivo6,
      subtitle: "Innovador servicio de limpieza",
      title: "en altura a través de dron",
      seo:"activos de nuestros equipos"
    },
  ];
  

  const Portadahome = () => {
    const [responsiveImages, setResponsiveImages] = useState(data.map(item => item.originalImage));
  
    const updateImages = () => {
      const newImages = data.map(item =>
        window.innerWidth <= 700 ? item.responsiveImage : item.originalImage
      );
      setResponsiveImages(newImages);
    };
  
    useEffect(() => {
      updateImages();
      window.addEventListener('resize', updateImages);
      return () => {
        window.removeEventListener('resize', updateImages);
      };
    }, []);
  
  
    useEffect(() => {
      AOS.init({
        duration: 500,
        once: true,
      });
    }, []);
  
    return (
      <section className="portada-home" data-aos="zoom-out">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          rewind={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay,Navigation, Pagination]}
          className="mySwiper-home"
        >
          {data.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="contiene-portada-home">
                <img
                  src={responsiveImages[index]}
                  alt={slide.seo}
                  priority={true}
                  title={slide.seo}
                />
                <div className="info-portada-home">
                  <h3 className="subtitulo-portada-home">{slide.subtitle}</h3>
                  <strong>
                    <h1 className="titulo-portada-home">{slide.title}</h1>
                  </strong>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    );
  };
  
  export default Portadahome;