import React, {useState} from "react";
import "../../style/formulario/formulario.css";
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  FormControl,
  InputLabel,
  MenuItem, // Cambiado de '@mui/joy/Option'
  Select,
  Grid,
  InputAdornment,
  TextField,
  Button,
  Modal,
  Box
} from "@mui/material";

import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import CircularProgress from '@mui/material/CircularProgress';


/* imagenes */

import logo from "../../img/navbar/logo.png";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  fontSize:"0.8rem",
  fontFamily:'Compasse',
  color: '#55565a',
  '& .MuiInputLabel-root':{
    fontSize:"0.9rem",
  },
  '& .MuiOutlinedInput-root':{
    borderRadius:"40px",
    fontSize:"0.8rem",
    color: '#55565a',
  },
  '@media (max-width: 900px)':{
             '& .MuiInputLabel-root':{
    fontSize:"0.7rem",
  },
  '& .MuiOutlinedInput-root':{
    borderRadius:"40px",
    fontSize:"0.7rem",
    color: '#55565a',
  },
            }

}))
const StyledSelect = styled(Select)(({ theme, error }) => ({
  fontSize:"0.8rem",
  borderRadius:"40px",
  fontFamily:'Compasse',
  color: '#55565a',
 
  '& .MuiInputLabel-root':{
    fontSize:"0.9rem", 
  },
  '& .MuiSelect-root':{
    borderRadius:"40px",
    fontSize:"0.8rem",
    color: '#55565a',
  },
  '& .MuiOutlinedInput-root':{
    borderRadius:"40px",
    fontSize:"0.8rem",
    color: '#55565a',
  }

}))
const StyledInputLabel = styled(InputLabel)(({ theme, error }) => ({
  fontSize:"0.8rem",
  '@media (max-width: 900px)':{
    fontSize:"0.7rem",
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  fontSize: '0.7rem',
  textTransform: 'none',
  color: '#55565a',
  fontFamily:'Compasse',
  margin:'-10px'
}))

export const Formulario = () => {
  const [responseStatus, setResponseStatus] = useState(null);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setEnviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [formData, setFormData] = useState({
    nombre: "",
    empresa: "",
    correo: "",
    telefono: "",
    servicios: [],
    mensajes: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "servicios") {
      setFormData((prevData) => ({
        ...prevData,
        servicios: value,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEnviandoAlert(true);
    try {
      const response = await fetch("https://www.mailaxinntus.axinntus.com/mailServicios.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const status = response.status;
      setResponseStatus(status);

      if (status === 200) {
        if (window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-FCWoCKjustEZEOH1jY8-",
          });
        }
        setEnviandoAlert(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Su mensaje fue enviado con éxito.');
        setFormData({
          nombre: "",
          empresa: "",
          correo: "",
          telefono: "",
          servicios: [],
          mensajes: "",
        });
      } else {
        setEnviandoAlert(false);
        setErrorAlertOpen(true);
        setAlertMessage('Error al enviar.');
        setTimeout(() => {
          setErrorAlertOpen(false);
        }, 4000);
      }
    } catch (error) {
      setEnviandoAlert(false);
      setErrorAlertOpen(true);
      setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
    } finally {
      setTimeout(() => {
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
      }, 4000);
    }
  };

  return (
    <section className="formulario">
      <section className="cabeceralogo">
        <img src={logo} alt="axinntus" />
      </section>
      <section className="titulo-formulario">
        <h1>Cotizar un servicio</h1>
        <span>Déjanos tu solicitud y una persona de nuestro equipo te contactará a la brevedad para atender tu requerimiento.</span>
      </section>
      <section className="formulario-requerimiento">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
              <StyledTextField
                id="nombre"
                name="nombre"
                label="Nombre"
                variant="outlined"
                fullWidth
                value={formData.nombre}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
              <StyledTextField
                id="empresa"
                name="empresa"
                label="Empresa"
                variant="outlined"
                fullWidth
                value={formData.empresa}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={8} sx={{ padding: '10px' }}>
              <StyledTextField
                id="correo"
                name="correo"
                label="Correo Electrónico"
                variant="outlined"
                type="email"
                fullWidth
                value={formData.correo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
              <StyledTextField
                id="telefono"
                name="telefono"
                variant="outlined"
                type="tel"
                InputProps={{
                  startAdornment: (
                    <InputAdornment id="telefono" position="start">
                      +569
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={formData.telefono}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ padding: '10px', marginBottom: '0px' }}>
              <FormControl fullWidth>
                <StyledInputLabel id="servicios-label" shrink={!!formData.servicios.length}>
                  Operación y mantenimiento de plantas desaladoras
                </StyledInputLabel>
                <StyledSelect
                  labelId="servicios-label"
                  id="servicios"
                  name="servicios"
                  IconComponent={KeyboardArrowDownIcon}
                  value={formData.servicios}
                  onChange={handleChange}
                  multiple
                >
                  <StyledMenuItem value="Manejo de Graneles Sólidos">Manejo de Graneles Sólidos</StyledMenuItem>
                  <StyledMenuItem value="Logística interna y transferencia de material">Logística interna y transferencia de material</StyledMenuItem>
                  <StyledMenuItem value="Limpieza industrial y aseo integral">Limpieza industrial</StyledMenuItem>
                  <StyledMenuItem value="Limpieza de Superficies y Estructuras industriales con dron">Limpieza de Superficies y Estructuras industriales con dron</StyledMenuItem>
                  <StyledMenuItem value="Mantenimiento industrial y gestión de activos">Mantenimiento industrial</StyledMenuItem>
                  <StyledMenuItem value="Otras soluciones industriales a la medida">Otras soluciones industriales a la medida</StyledMenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ padding: '10px' }}>
              <TextField
                sx={{ fontSize: "0.8rem" }}
                id="mensajes"
                name="mensajes"
                label="Mensaje"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={formData.mensajes}
                onChange={handleChange}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button type="submit" className="buton-enviar">Enviar solicitud de información</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </section>

      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '1rem', fontWeight: '600' }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Compasse', color: '#585857', fontSize: '1rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Compasse', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      <Modal open={enviandoAlert} onClose={() => setEnviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#6fa967' }} />
            <p style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
    </section>
  );
}