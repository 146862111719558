import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Button, Menu, MenuItem } from '@mui/material';
import { FaInstagram, FaLinkedinIn, FaFacebookF, FaYoutube } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import "../../style/navbar/navbar.css"



const NavBarWeb = ({ logo, logodos, handleClick, handleClose, anchorEl, open, openPopup }) => (
  <>
    <section className="navbar-web-superior">
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item xs={6}>
            <div className="contiene-navbar-superior-informacion">
              <button>
                <Link to="/Nuestroequipo">Únete a nuestro equipo</Link>
              </button>
              <div className="lineaseparador"></div>
              <Button
                className={`superior ${open ? "active" : ""}`}
                id="basicbuttonnavbarsuperior"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Sitios internos Axinntus
                <KeyboardArrowDownIcon fontSize="small" className={open ? "rotar180" : ""} />
              </Button>
              <Menu
                className="superior-item"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                {[
                  { href: "https://miportal.axinntus.com/app/authentication", label: "Mi Portal Axinntus" },
                  { href: "https://www.crehana.com/org/axinntus/entrar/?next=/org/axinntus/panel/", label: "Portal de capacitación" },
                  { href: "https://ultramar.gestionpersonas.cl/", label: "Plataforma Gestión de Personas Konectus" },
                  { href: "https://agenciaorbita.cl/axinntus/firma2024/", label: "Configuracion firma de correo electrónico" },
                  { href: "https://portal.winper.cl/axinntus/#/", label: "Plataforma de remuneraciones Winper" }
                ].map((link, index) => (
                  <MenuItem key={index} className="link-nosotros" onClick={handleClose}>
                    <a href={link.href}>{link.label}</a>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="contenedor-redes-sociales-superior">
              <span>Síguenos en:</span>
              {[
                { href: "https://www.instagram.com/axinntus/", icon: <FaInstagram className="iconos-contacto" /> },
                { href: "https://www.linkedin.com/company/axinntus?originalSubdomain=cl", icon: <FaLinkedinIn className="iconos-contacto" /> },
                { href: "https://www.facebook.com/Axinntus/?locale=es_LA", icon: <FaFacebookF className="iconos-contacto" /> },
                { href: "https://www.youtube.com/@axinntus7615", icon: <FaYoutube className="iconos-contacto" /> }
              ].map((social, index) => (
                <button key={index} className="contacto-icono-redes">
                  <a href={social.href} target="blank">{social.icon}</a>
                </button>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
    <section className="navbar-web-inferior">
      <Container maxWidth='xl'sx={{paddingBottom:'18px'}}>
        <Grid container style={{ position: "relative", top: "8px", marginBottom: '0px' }}>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="contenedorimagenlogo">
              <Link to="/">
              
                <img src={logo} alt="axinntus"  title="axinntus" className="logoaxinntus" />
                
              </Link>
            </div>
          </Grid>
          <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <NavBarLinks />
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <StyledButtoncotizar className="boton-cotizar" variant="contained" onClick={openPopup}>Solicitar más información</StyledButtoncotizar>
          </Grid>
        </Grid>
      </Container>
    </section>
  </>
);

const NavBarLinks = () => (
  <>
    {[
      {
        label: "Sobre Nosotros",
        links: [
          { href: "/Nosotros/Portada", label: "Quienes Somos" },
          { href: "/Nosotros/Propositos", label: "Propósito, misión, visión y valores" },
          { href: "/Nosotros/Historia", label: "Nuestra historia y trayectoria" },
          { href: "/Nosotros/Personas", label: "Nuestras personas" },
          { href: "/Nosotros/Mapa", label: "Operaciones y contratos" }
        ]
      },
      {
        label: "Nuestros servicios",
        links: [
          { href: "/Servicios/ManejoSolidos", label: "Manejo de Graneles Sólidos" },
          { href: "/Servicios/Logistica", label: "Logística interna y transferencia de material" },
          { href: "/Servicios/Limpiezaindustrial", label: "Limpieza industrial" },
          { href: "/Servicios/Mantenimiento", label: "Mantenimiento industrial" },
          { href: "/Servicios/Soluciones", label: "Otras soluciones industriales a la medida" }, 
           { href: "/Servicios/Limpieza", label: "Limpieza de Superficies y Estructuras industriales con dron" },
          { href: "/Servicios/Equipo", label: "Nuestros equipos" },
          { href: "/Servicios/Confianza", label: "Nuestros clientes" }
        ]
      },
      {
        label: "Seguridad, Salud Ocupacional, Medioambiente y Calidad",
        links: [
          { href: "/Seguridad/Portada", label: "Política de Seguridad, Salud Ocupacional, Medioambiente y Calidad" },
          { href: "/Seguridad/Foco", label: "Focos de seguridad y socioambientales de Ultramar" },
          { href: "/Seguridad/Estandar", label: "Estándares Riesgos Críticos 4 A+C" },
          { href: "/Seguridad/Proteccion", label: "Estándares de Protección Ambiental" },
          { href: "/Seguridad/Regla", label: " Reglas de Oro para el Cuidado de la Vida" },
          { href: "/Seguridad/Iniciativa", label: "Iniciativas y proyectos de medioambiente" },
          { href: "/Seguridad/Galerias", label: "Galería de fotos actividades más destacadas" }
        ]
      },
      {
        label: "Sostenibilidad",
        links: [
          { href: "/Sostenibilidad/Portada", label: "Pilares de Sostenibilidad" },
          { href: "/Sostenibilidad/Inclusion", label: "Inclusión y Diversidad" },
          { href: "/Sostenibilidad/Relacionamiento", label: "Programas Comunitarios" },
          { href: "/Sostenibilidad/Galeria", label: "Galería de fotos actividades más destacadas" }
        ]
      },
     {
        label: 'Canal de consultas y denuncias',
        linklabel: 'https://www.eticaaxinntus.cl'
      } 
    ].map((menu, index) => (
      <NavBarDropdown key={index} label={menu.label} links={menu.links} linklabel={menu.linklabel} />
    ))}
  </>
);

const NavBarDropdown = ({ label, links, linklabel }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (links && links.length > 0) {
      setAnchorEl(event.currentTarget);
    } else if (linklabel) {
      window.open(linklabel, "_blank", "noopener noreferrer");
    }
  };

  const handleClose = () => setAnchorEl(null);

  const showArrow = links && links.length > 0;

  return (
    <div className="contenedor-de-link-navbar">
      <StyledButton
        className={`contiene-link ${Boolean(anchorEl) ? 'active' : ''}`}
        id={`basic-button-${label}`}
        aria-controls={Boolean(anchorEl) ? `basic-menu-${label}` : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        onClick={handleClick}
      >
        {label}
        {showArrow && <KeyboardArrowDownIcon fontSize="small" className={Boolean(anchorEl) ? 'rotar180' : ''} />}
      </StyledButton>
      <StyledMenu
        className="nosotros"
        id={`basic-menu-${label}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': `basic-button-${label}` }}
      >
        {links && links.length > 0 && (
          links.map((link, index) => (
            <StyledMenuItem
              key={index}
              className="link-nosotros"
              onClick={handleClose}
            >
              <Link to={link.href}>
                {link.label}
              </Link>
            </StyledMenuItem>
          ))
        )}
      </StyledMenu>
    </div>
  );
};


export default NavBarWeb;


 const StyledButton = styled(Button)(({ theme, error }) => ({
  padding:'15px 20px',
  fontSize:'0.9rem',
  color: '#55565a',
  textAlign:'center',
  fontFamily: 'Compasse',
  background:'trasparent',
  textTransform:'none',
  lineHeight:'1.2',
  fontWeight:'400',
  '@media (max-width: 1340px)':{
    padding:'15px 10px',
  },
  '@media (max-width: 1300px)':{
    fontSize:'0.8rem',
  },
  '@media (max-width: 1090px)':{
    fontSize:'0.7rem',
    padding:'10px 0px',
  },
}));

const StyledButtoncotizar = styled(Button)(({ theme, error }) => ({
  padding:'12px 10px',
  width:'100%',
  border:'0px',
  borderRadius:'50px',
  fontSize:'0.9rem',
  color: '#fff',
  background:'#6fa967',
  textTransform:'none',
  lineHeight:'1.2',
  fontWeight:'400',
  '@media (max-width: 1300px)':{
    fontSize:'0.8rem',
  },
  '@media (max-width: 1090px)':{
    fontSize:'0.6rem',
    padding:'12px 0px',
  },
}));

const StyledMenu = styled(Menu)(({ theme, error }) => ({
  padding: '13px 30px 20px 18px',
  fontSize: '0.9rem',
  textTransform: 'none',
  color: '#55565a',
  fontFamily:'Compasse',
  borderRadius: '0px 0px 8px 8px',
  boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
  border: '0px',
  paddingTop: '7px',
  paddingBottom: '7px',
  position:'absolute',
    overflowY: 'auto', // Agrega las propiedades de estilo aquí
    overflowX: 'hidden',
   
    outline: '0',
    WebkitOverflowScrolling: 'touch',
  top: '10px',
  '& .MuiPaper-root': { // Estilo directamente el componente Paper dentro de Menu
    backgroundColor: '#fff',
    fontSize: '0.9rem',
    color: '#55565a',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    
  },
  '& .MuiList-root':{
    padding:'15px'
  }
})); 

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
    fontSize: '0.9rem',
    textTransform: 'none',
    color: '#55565a',
    fontFamily:'Compasse',
}))